import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";

import api from "../services/global/api-feargreed";
import Footer from "../components/footer";
import Button from "../components/button";
import Indicators from "../components/paradigma-pro/indicators";
import SubscriptionTable from "../components/paradigma-pro/subscription-table";
import Community from "../components/paradigma-pro/community";
import CommonQuestions from "../components/paradigma-pro/common-questions";
import ActionBar from "../components/header/action-bar";
import HeaderMenu from "../components/header/menu";
import Modals from "../components/modals";
import moeda from "../images/image-moeda.png";
import logocarteira from "../images/icons8-inspection-2.svg";
import logorelatorio from "../images/icons8-news-1.svg";
import logominicurso from "../images/icons8-video-1.svg";
import logoideiasalternativas from "../images/icons8-financial-growth-analysis-1.svg";
import logoindicadores from "../images/icons8-heat-map-1.svg";
import logovaloracao from "../images/icons8-bitcoin-1.svg";
import imageaspas from "../images/“.png";
import group from "../images/Group-10948.png";
import rotatomobile from "../images/Rotato-Mobile-Boletim-Invertido.png";
import imgHero from "../images/img-hero-paradigma-pro@2x.png";
import setalightgreen from "../images/seta-light-green.svg";
import {
  openModal,
  modalType,
  setPeriod,
  setModal,
} from "../store/actions/modals";

const mapState = (state) => ({
  modals: state.modals,
});

export const ParadigmaPro = ({
  modals,
  openModal,
  modalType,
  setPeriod,
  setModal,
}) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [reports, setReports] = useState("59");
  const [type] = useState("Pro");

  useEffect(() => {
    if (urlParams.get("coupon")) {
      openModal(true);
      setModal(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, setModal]);

  useEffect(() => {
    async function getReports() {
      try {
        const response = await api.get("/api/v2/website/reports-count");
        setReports(response.data.count);
      } catch (e) {
        console.log(e);
      }
    }
    getReports();
  }, []);

  useEffect(() => {
    if (openModal) {
      modalType(type);
    }
  }, [modalType, type, openModal]);

  const onClickSwitcher = () => {
    if (modals.period) {
      setPeriod(false);
    } else {
      setPeriod(true);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <MetaTags>
          <title>Turbine seus investimentos</title>
          <meta
            name="description"
            content="Encontre as melhores oportunidades de investimento no mercado mais acelerado do planeta. Simples, transparente, direto ao ponto"
          />
          <meta property="og:title" content="Turbine seus investimentos" />
          <meta
            property="og:description"
            content="Encontre as melhores oportunidades de investimento no mercado mais acelerado do planeta. Simples, transparente, direto ao ponto"
          />
        </MetaTags>
        <ActionBar />
        <div className="main-wrapper">
          <div className="section_header page-pro-background">
            <HeaderMenu current="pro" />
            <div className="page-padding">
              <div className="container-large padding-vertical padding-huge">
                <div className="main-grid grid-custom">
                  <div
                    id="w-node-_4758ae2d-3723-6356-40c0-4e8dd8087bb4-71152178"
                    className="grid-wrapper"
                  >
                    <div className="flex-horizontal-bottom">
                      <div className="text-size-xlarge">Paradigma Pro</div>
                      <div className="flex-number">
                        <div className="text-size-medium text-color-white text-weight-semibold">
                          R$ 119,90
                        </div>
                      </div>
                    </div>
                    <div className="flex-horizontal-bottom max-width-xlarge margin-vertical margin-xsmall">
                      <h2 className="heading-xhuge">
                        Turbine seus investimentos
                      </h2>
                      <img
                        src={moeda}
                        loading="lazy"
                        alt=""
                        className="emoji-money"
                      />
                    </div>
                    <div className="text-size-large text-color-dark-gray">
                      Encontre as{" "}
                      <span className="text-color-darkest-gray text-weight-medium">
                        melhores oportunidades de investimento
                      </span>{" "}
                      no mercado mais acelerado do planeta. Simples,
                      transparente, direto ao ponto.
                    </div>
                    <div
                      className="buttons-wrapper margin-top margin-large"
                      onClick={() => {
                        openModal(!modals.isOpened);
                      }}
                    >
                      <Button
                        id="4758ae2d-3723-6356-40c0-4e8dd8087bbe"
                        namesClass="button-large w-inline-block"
                        title="ASSINE AGORA"
                      />
                    </div>
                  </div>
                  <div
                    id="w-node-_4758ae2d-3723-6356-40c0-4e8dd8087bc4-71152178"
                    className="grid-wrapper custom-inner"
                  >
                    <img
                      src={imgHero}
                      srcSet={imgHero}
                      loading="lazy"
                      id="w-node-_981e73c5-53a5-2281-123e-afec0277f944-71152178"
                      sizes="(max-width: 767px) 100vw, (max-width: 991px) 95vw, 816px"
                      alt=""
                      className="full-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="page-padding">
              <div className="container-large padding-bottom padding-xlarge">
                <div className="text-size-medium text-color-light-gray text-weight-semibold text-style-allcaps ">
                  O que você ganha ao liberar seu acesso
                </div>
                <div className="main-grid page-pro margin-vertical margin-xlarge">
                  {/* <AboutAccess id="w-node-_8825f63d-4f82-0115-3c8b-ba5d787e83fb-71152178" image={logocarteira} title="Carteiras Temáticas" subtitle="Carro-chefe" text="Seleção de ativos que julgamos ser as melhores oportunidades no universo cripto. Segmentadodos pelos temas mais promissores."/>
                <AboutAccess id="w-node-e2995616-b56b-8998-a5a3-afbee93afd2f-71152178" image={logorelatorio} title="Relatórios" text="Boletins, panoramas, notícias relevantes e guias. Filtramos o que realmente importa e te entregamos mastigado."/>
                <AboutAccess id="w-node-aeb547d2-e557-7080-b690-61fe5547502b-71152178" image={logominicurso} title="Mini-cursos" text="Aprenda o passo-a-passo na prática com tutoriais sobre diversos temas e fatos relevantes no formato mais preferido da internet."/>
                <AboutAccess id="w-node-e3c78479-2541-3537-c710-54acc705bc24-71152178" image={logoideiasalternativas} title="Ideias alternativas de investimento" subtitle="Tem estômago?" text="Ideias sobre projetos em fases muito iniciais ou de capitalização de mercado muito baixa."/>
                <AboutAccess id="w-node-_216ad6c4-877d-0395-5c98-473a30c5900e-71152178" image={logoindicadores} title="Indicadores On-chain" text="Dados para você descobrir sinais e antever grandes movimentos. Conexão direta com a blockchain e as maiores bolsas do mundo."/>
                <AboutAccess id="w-node-_65071f81-56f9-c1a0-3a6e-02c000c8be6a-71152178" image={logovaloracao} title="Modelos de Valoração" text="Explore métodos de precificação do bitcoin. Confira suas previsões e aprenda a julgar se está caro ou barato."/> */}

                  <div
                    id="w-node-_8825f63d-4f82-0115-3c8b-ba5d787e83fb-71152178"
                    className="max-width-small card-mobile"
                  >
                    <img src={logocarteira} loading="lazy" alt="" />
                    <div className="text-size-large text-weight-semibold margin-top margin-xsmall">
                      Carteiras Temáticas
                    </div>
                    <div className="scorer">
                      <div className="text-size-small text-style-allcaps text-color-gray">
                        Carro-chefe
                      </div>
                    </div>
                    <div
                      className="text-size-large text-color-dark-gray margin-top margin-small"
                      style={{ fontSize: "16px" }}
                    >
                      Seleção de ativos que julgamos ser as melhores
                      oportunidades no universo cripto. Segmentados pelos temas
                      mais promissores.
                    </div>
                  </div>

                  <div
                    id="w-node-e2995616-b56b-8998-a5a3-afbee93afd2f-71152178"
                    className="max-width-small card-mobile"
                  >
                    <img src={logorelatorio} loading="lazy" alt="" />
                    <div className="text-size-large text-weight-semibold margin-top margin-xsmall">
                      Relatórios
                    </div>
                    <div
                      className="text-size-large text-color-dark-gray margin-top margin-small"
                      style={{ fontSize: "16px" }}
                    >
                      Boletins, panoramas, notícias relevantes e guias.
                      Filtramos o que realmente importa e te entregamos
                      mastigado.
                    </div>
                  </div>

                  <div
                    id="w-node-aeb547d2-e557-7080-b690-61fe5547502b-71152178"
                    className="max-width-small card-mobile"
                  >
                    <img src={logominicurso} loading="lazy" alt="" />
                    <div className="text-size-large text-weight-semibold margin-top margin-xsmall">
                      Mini-cursos
                    </div>
                    <div
                      className="text-size-large text-color-dark-gray margin-top margin-small"
                      style={{ fontSize: "16px" }}
                    >
                      Aprenda o passo-a-passo na prática com tutoriais sobre
                      diversos temas e fatos relevantes no formato mais
                      preferido da internet.
                    </div>
                  </div>

                  <div
                    id="w-node-e3c78479-2541-3537-c710-54acc705bc24-71152178"
                    className="max-width-small card-mobile"
                  >
                    <img src={logoideiasalternativas} loading="lazy" alt="" />
                    <div className="text-size-large text-weight-semibold margin-top margin-xsmall">
                      Ideias alternativas de investimento
                    </div>
                    <div className="scorer">
                      <div className="text-size-small text-style-allcaps text-color-gray">
                        Tem estômago?
                      </div>
                    </div>
                    <div
                      className="text-size-large text-color-dark-gray margin-top margin-small"
                      style={{ fontSize: "16px" }}
                    >
                      Ideias sobre projetos em fases muito iniciais ou de
                      capitalização de mercado muito baixa.
                    </div>
                  </div>

                  <div
                    id="w-node-_216ad6c4-877d-0395-5c98-473a30c5900e-71152178"
                    className="max-width-small card-mobile"
                  >
                    <img src={logoindicadores} loading="lazy" alt="" />
                    <div className="text-size-large text-weight-semibold margin-top margin-xsmall">
                      Indicadores On-chain
                    </div>
                    <div
                      className="text-size-large text-color-dark-gray margin-top margin-small"
                      style={{ fontSize: "16px" }}
                    >
                      Dados para você descobrir sinais e antever grandes
                      movimentos. Conexão direta com a blockchain e as maiores
                      bolsas do mundo.
                    </div>
                  </div>

                  <div
                    id="w-node-_65071f81-56f9-c1a0-3a6e-02c000c8be6a-71152178"
                    className="max-width-small card-mobile"
                  >
                    <img src={logovaloracao} loading="lazy" alt="" />
                    <div className="text-size-large text-weight-semibold margin-top margin-xsmall">
                      Modelos de Valoração
                    </div>
                    <div
                      className="text-size-large text-color-dark-gray margin-top margin-small"
                      style={{ fontSize: "16px" }}
                    >
                      Explore métodos de precificação do bitcoin. Confira suas
                      previsões e aprenda a julgar se está caro ou barato.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Indicators />
          <div className="section">
            <div className="page-padding">
              <div className="container-large padding-vertical padding-huge">
                <h2 className="heading-xlarge text-align-center margin-bottom">
                  Escolha seu plano
                </h2>
                <div
                  style={{ justifyContent: "center" }}
                  className="flex-horizontal-center"
                >
                  <div className="text-size-large text-font-manrope text-color-dark-gray">
                    Quadrimestral
                  </div>
                  <div
                    className="selector-button margin-horizontal margin-xxsmall"
                    style={{
                      background:
                        "linear-gradient(110.26deg, #FB864A 14.28%, #F94D77 73.52%",
                    }}
                    onClick={onClickSwitcher}
                  >
                    <div
                      className={
                        !modals.period
                          ? "selector-ball-quadri"
                          : "selector-ball-anual"
                      }
                    ></div>
                  </div>
                  <div className="text-size-large text-font-manrope text-color-dark-gray">
                    Anual
                  </div>
                  <div className="flex-number size-small">
                    <div className="text-size-small text-color-white">
                      25% OFF
                    </div>
                  </div>
                </div>
                <SubscriptionTable plan={modals.pplan} />

                <div className="main-grid grid-custom margin-custom-left margin-vertical margin-huge">
                  <div
                    id="w-node-_04f8d9a2-2aed-64fe-efd8-f19bda242180-71152178"
                    className="grid-wrapper"
                  >
                    <div
                      id="w-node-_623dd891-df92-faff-063c-c059fce7cf93-71152178"
                      className="max-width-medium margin-bottom margin-huge"
                    >
                      <h2 className="heading-xxhuge text-color-pink margin-bottom margin-small">
                        {reports}
                      </h2>
                      <h2 className="heading-xlarge">
                        relatórios com as oportunidades + quentes
                      </h2>
                      <div className="text-size-large text-color-dark-gray margin-top margin-xsmall">
                        A cada 45 dias, te mandamos um relatório de longo
                        formato. As idéias de investimento antes restritas a
                        fundos e tubarões, agora numa linguagem que você pode
                        entender e aplicar.
                      </div>
                    </div>

                    <div className="max-width-medium margin-left margin-huge">
                      <div className="flex-horizontal-align-center">
                        <img
                          src={imageaspas}
                          loading="lazy"
                          alt=""
                          className="image-6"
                        />
                        <h2 className="heading-xlarge">As FAANG da Web3</h2>
                      </div>

                      <div className="div-block-10">
                        <div className="text-size-large text-color-dark-gray margin-vertical margin-xsmall">
                          Conheça 5 protocolos (com tokens) que podem desbancar
                          ou competir com os maiores olgopólios da web 2.0.
                        </div>
                        <div className="buttons-wrapper">
                          {/* <Button id="9e10a641-1e1d-b7e7-e177-adbb9d8be7bb" namesClassName="button-text w-inline-block" title="Leia mais" link="https://app.paradigma.education/feed"/> */}
                          {/* <img src={setalightgreen} loading="lazy" alt="" className="icon-xsmall margin-left margin-xxsmall"/> */}
                          <a
                            data-w-id="9e10a641-1e1d-b7e7-e177-adbb9d8be7bb"
                            href="https://app.paradigma.education/feed/post/view?id=wKj38qygPUYSgVugn3BC"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="button-text w-inline-block"
                          >
                            <div>Leia mais</div>
                            <img
                              src={setalightgreen}
                              loading="lazy"
                              alt=""
                              className="icon-xsmall margin-left margin-xxsmall"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="w-node-_44b815d9-6324-5161-324d-7fa96d9737d7-71152178"
                    className="grid-wrapper custom-inner size-image"
                  >
                    <img
                      src={group}
                      loading="lazy"
                      sizes="(max-width: 479px) 92vw, (max-width: 767px) 600px, (max-width: 991px) 526.390625px, 752px"
                      alt=""
                      className="full-image fix-mobile"
                    />
                  </div>
                </div>

                <div className="main-grid grid-duo margin-top margin-huge">
                  <div
                    id="w-node-_71100703-8d2e-9843-8156-41ae28b7b061-71152178"
                    className="grid-wrapper"
                  >
                    <div
                      id="w-node-_71100703-8d2e-9843-8156-41ae28b7b062-71152178"
                      className="max-width-medium"
                    >
                      <h2 className="heading-xlarge">Boletim semanal</h2>
                      <div
                        className="text-size-large text-color-dark-gray margin-top margin-xsmall"
                        style={{ fontSize: "16px" }}
                      >
                        Gaste menos tempo filtrando notícias. Receba os produtos
                        mais inovadores, status da blockchain e projetos com
                        maior potencial de valorização, toda semana, na sua
                        caixa de entrada.
                      </div>
                    </div>
                    <div
                      className="buttons-wrapper margin-top margin-medium"
                      onClick={() => {
                        openModal(!modals.isOpened);
                      }}
                    >
                      <Button
                        id="cdfec08f-3ae4-19b4-aac3-3fef37e7c041"
                        namesClass="button-large w-inline-block"
                        title="Comece a receber já"
                      />
                      {/* <img src={setagreen} loading="lazy" alt="" className="icon-xsmall margin-left margin-xxsmall"/> */}
                    </div>
                  </div>
                  <div
                    id="w-node-_71100703-8d2e-9843-8156-41ae28b7b05f-71152178"
                    className="grid-wrapper mobile"
                  >
                    <img
                      src={rotatomobile}
                      loading="lazy"
                      sizes="(max-width: 479px) 85vw, (max-width: 767px) 325.890625px, (max-width: 991px) 39vw, 389.90625px"
                      alt=""
                      className="image-phone"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Community
            modals={modals}
            openModal={openModal}
            modalType={modalType}
          />
          <CommonQuestions />
        </div>
        <Footer />
      </div>
      {modals.isOpened && <Modals />}
    </>
  );
};

export default connect(mapState, { openModal, modalType, setPeriod, setModal })(
  ParadigmaPro
);
