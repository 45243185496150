import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import {
  CardElement,
  AddressElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import api from "../../../services/global/api-paradigma";
import download from "../../../images/download-1-1.png";
import LoadingGif from "../../../images/loading-button.gif";
import Message from "../message";
import { validCnpj, validCpf } from "../../../utils";
import { TextMaskCardCPF } from "../../../utils";
const StripeForm = ({ modals }) => {
  const stripe = useStripe();
  const elements = useElements();
  var formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  const initialValue = {
    neighborhood: "",
    street: "",
    streetNumber: "",
    postal_code: "",
    state: "",
    country: "BR",
    city: "",
    documentNumber: "",
    email: "",
    pplan: modals.pplan,
    paymentMethod: "credit_card",
    cardToken: "",
  };

  const [values, setValues] = useState(initialValue);
  const [text, setText] = useState("");
  const [success, setSuccess] = useState(false);
  const [visible, setVisible] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [cpfNumNotValid, setCpfNumNotValid] = useState("");
  const [loading, setLoading] = useState(false);
  const onChange = (ev) => {
    const { name, value } = ev.target;
    if (name === "documentNumber") {
      if (
        validCnpj(value.replace(/(\.|-|\/)/g, "").trim()) ||
        validCpf(value.replace(/(\.|-|\/)/g, "").trim())
      ) {
        setCpfNumNotValid(false);
      } else {
        setCpfNumNotValid(true);
      }
    }
    setValues({ ...values, [name]: value });
  };

  const onChangeAddr = async (event) => {
    if (event.complete) {
      // Extract potentially complete address
      const address = event.value.address;
      setValues({ ...values, ...address, name: event.value.name });
    }
  };
  const onSubmitStripe = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement("card");
    const result = await stripe.createToken(cardElement, { country: "BR" });

    if (!result.token) {
      setText(result?.error?.message);
      Sentry.captureException(result.err, { email: modals.email });
    }

    let body = {
      customer: {
        address: {
          line2: values.line2,
          line1: values.line1,
          state: values.state,
          postalCode: values.postal_code,
          country: values.country,
          city: values.city,
        },
        documentNumber: values.documentNumber.replace(/(\.|-|\/)/g, "").trim(),
        email: modals.email,
        name: values.name,
      },
      pplan: modals.pplan,
      paymentMethod: "credit_card",
      cardToken: result?.token?.id,
    };
    setSubmitDisabled(true);
    setLoading(true);
    try {
      const authRes = await api.post("/api/v2/auth/signup", {
        email: modals.email,
      });
      const token = authRes.data.token;
      window.localStorage.setItem(`_session`, JSON.stringify(token));
      if (modals.discount !== undefined) {
        body = {
          ...body,
          coupon: modals.codeId,
        };
      }
      const paymentRes = await api.post("/api/v2/subscriptions", body, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const newToken = await api.get("/api/v2/auth/generate", {
        headers: { Authorization: `Bearer ${token}` },
      });
      window.localStorage.setItem(
        `_session`,
        JSON.stringify(newToken.data.token)
      );
      if (paymentRes) {
        setText("Assinatura concluida!");
        setTimeout(() => {
          if (!window.location.pathname.includes("trilhas")) {
            window.location.href = "https://app.paradigma.education";
          } else {
            window.location.href = `https://app.paradigma.education/trilhas/${
              window.location.pathname.split("/")[2]
            }`;
          }
        }, 1500);
      }
    } catch (err) {
      setLoading(false);
      setVisible(true);
      setSuccess(false);
      Sentry.captureException(err, { email: modals.email });
      setSubmitDisabled(false);
      if (err.response) {
        setText(err.response.data.data);
      }
      if (!err.response) {
        setText("Preencha todos os campos obrigatórios!");
      }
    }
  };

  const containerStyles = {
    padding: "11px 12px",
    height: "42px",
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    color: "#212",
  };

  return (
    <>
      <div className="flex-center margin-bottom margin-medium">
        <img src={download} loading="lazy" alt="" className="modal-image" />
      </div>
      <div className="form-wrapper w-form form-wrapper-scroll">
        {visible && (
          success 
            ? <Message text={text} success /> 
            : <Message text={text} />
          )
        }
        <form
          id="email-form-2"
          className="form"
          autoComplete="off"
          onSubmit={onSubmitStripe}
        >
          <div className="input-group" style={{ marginBottom: ".75rem" }}>
            <label className="text-size-medium margin-botton margin-xxsmall text-size-line">
              Dados do cartão
            </label>
            <div style={containerStyles}>
              <CardElement
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      fontSize: "14px",
                      fontSmoothing: "antialiased",
                      fontFamily: "Inter",
                    },
                    invalid: {
                      color: "red",
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className="input-group" style={{ marginBottom: ".75rem" }}>
            <label
              htmlFor="documentNumber"
              className="text-size-medium margin-botton margin-xxsmall text-size-line"
            >
              CPF (ou CNPJ)
            </label>
            <TextMaskCardCPF
              id="documentNumber"
              name="documentNumber"
              type="text"
              className="text-field w-input"
              style={{
                color: cpfNumNotValid ? "red" : "#212",
              }}
              placeholder="Número do documento"
              onChange={onChange}
              data-name="Email 3"
            />
          </div>

          <div className="input-group">
            <AddressElement
              onChange={onChangeAddr}
              options={{
                autocomplete: {
                  mode: "google_maps_api",
                  apiKey: "AIzaSyCrU9BPyVYKDpDe181Lta1GPaNa3G32mB4",
                },
                mode: "billing",
                allowedCountries: ["BR"],
              }}
            />
          </div>
          <button
            className="button-modal margin-top margin-xsmall w-inline-block"
            type="submit"
            disabled={submitDisabled}
          >
            {!loading ? (
              <span>Pagar {formatter.format(modals.price)}</span>
            ) : (
              <div className="loader">
                <img
                  style={{ width: "20px" }}
                  src={LoadingGif}
                  alt="loading..."
                />
              </div>
            )}
          </button>
        </form>
      </div>
    </>
  );
};

export default StripeForm;
