import React from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import Slider from "react-slick";
import NextArrow from "../arrow/next-arrow";
import PrevArrow from "../arrow/prev-arrow";

import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// import lock from "../../../../images/lock-closed-16px.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CustomTooltip = ({ title, children }) => {
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }))(Tooltip);

  return (
    <LightTooltip enterTouchDelay={0} leaveTouchDelay={1000} title={title}>
      {children}
    </LightTooltip>
  );
};

const Module = ({ videos, module, slug }) => {
  const { idTrilhas } = useParams();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const settings = {
    speed: 500,
    slidesToShow: 4,
    adaptiveHeight: false,
    slidesToScroll: 4,
    centerMode: false,
    infinite: false,
    initialSlide: 0,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    className: "slide-items",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
 
  return (
    <>
      <div className="landing-tracks-text-primary-large container-padding-top">
        <div className="module-name">{module}</div>
        <Slider {...settings}>
          {videos.map((video, index) => (
            <div className="container-card" key={index}>
              <a 
                href={`https://app.paradigma.education/trilhas/${idTrilhas}/${slug}/${
                  video.slug
                }?videoTrack=${urlParams.get("id")}&id=${
                  video.id
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                
                {/* <div
                  className={
                    video.locked ? "container-lock-none" : "container-lock"
                  }
                >
                  <img src={lock} alt="lock" />
                </div> */}
                <div
                  className={
                    video.reactions.length > 0
                      ? "emojis-thumbnail"
                      : "emojis-thumbnail-none"
                  }
                >
                  {video.reactions?.slice(0, 3).map((item, index) => (
                    <CustomTooltip
                      title={`${moment(item.createdAt).format("DD/MM/YYYY")}`}
                      key={index}
                    >
                      <div
                        className="emoji-thumbnail"
                        role="img"
                        aria-label="emoji"
                      >
                        {item.reaction}
                      </div>
                    </CustomTooltip>
                  ))}
                </div>
                <div className="image-module-video">
                  <img
                    className="image-module-video"
                    src={video.thumbnail}
                    alt={video.name}
                  />
                </div>
              </a>
              <div className="text-padding-top">{video.name}</div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default Module;
