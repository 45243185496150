import React from "react";
import { connect } from "react-redux";

import TableRow from "./table-row.jsx";

import coinLight from "../../../images/coin-light.png";
import trading from "../../../images/trading-plan.png";
import coinPro from "../../../images/icon-moeda.svg";

import { openModal, modalType, setPPlan } from "../../../store/actions/modals";
import Modals from "../../modals";

const mapState = (state) => ({
  modals: state.modals,
});

const SubscriptionTable = ({ modals, openModal, modalType, setPPlan }) => {
  const open = (type) => {
    modalType(type);
    openModal(!modals.isOpened);
  };
  return (
    <>
      <div>
        <table className="subscription-table">
          <thead>
            <tr className="subscription-table-menu">
              <th scope="col"></th>
              <th className="table-cell table-col-relative" scope="col">
                <div className="table-column-fieldset">MAIS POPULAR</div>
                <img className="table-icon" src={coinPro} alt="imagem" />
                <h3>Plano Pro</h3>
                <p>
                  {modals.period ? 12 : 4}x{" "}
                  <span>R$ {modals.period ? "119,90" : "159,90"}</span>
                </p>
              </th>
              <th className="table-cell" scope="col">
                <img className="table-icon" src={trading} alt="imagem" />
                <h3>Plano Trading</h3>
                <p>
                  12x <span>R$ {modals.period ? "40,00" : "60,00"}</span>
                </p>
              </th>
              <th className="table-cell" scope="col">
                <img className="table-icon" src={coinLight} alt="imagem" />
                <h3>Plano Light</h3>
                <p>
                  {modals.period ? 12 : 4}x{" "}
                  <span>R$ {modals.period ? "29,90" : "49,90"}</span>
                </p>
              </th>
            </tr>
          </thead>

          <TableRow one={true} two={false} three={false} color={true}>
            <>
              🧠 Todos Nossos
              <span className="table-cell-text-light"> Relatórios</span>
            </>
          </TableRow>

          <TableRow one={true} two={false} three={true} color={false}>
            <>
              🎓 Todos Nossos
              <span className="table-cell-text-light"> Mini-Cursos</span>
            </>
          </TableRow>

          <TableRow one={true} two={false} three={true} color={true}>
            <>
              <span className="table-cell-text-dark">Boletim Semanal </span>
              toda Quinta
            </>
          </TableRow>

          <TableRow one={true} two={false} three={true} color={false}>
            <>Manual de Uso do Bitcoin</>
          </TableRow>

          <TableRow one={3} two={0} three={1} color={true} valuation={true}>
            <>Ferramentas de Valuation</>
          </TableRow>

          <TableRow one={true} two={true} three={false} color={false}>
            <>
              <span className="table-cell-text-dark">4 estratégias </span>
              quantitativas
            </>
          </TableRow>

          <TableRow one={true} two={true} three={false} color={true}>
            <>
              <span className="table-cell-text-dark">Sinais diários </span>
              de compra e venda
            </>
          </TableRow>

          <TableRow one={true} two={false} three={false} color={false}>
            <>
              🔥 Acesso às
              <span className="table-cell-text-light">
                {" "}
                Carteiras dos Analistas
              </span>
            </>
          </TableRow>

          <TableRow one={true} two={false} three={false} color={true}>
            <>
              💡
              <span className="table-cell-text-light"> Ideias de Trade </span>
              Fora da Caixa
            </>
          </TableRow>

          <TableRow one={true} two={false} three={false} color={false}>
            <>
              <span className="table-cell-text-dark">+15 Indicadores </span>
              Exclusivos
            </>
          </TableRow>

          <TableRow one={true} two={false} three={false} color={true}>
            <>
              <span className="table-cell-text-dark">Comunidade </span>
              no Discord
              <span className="table-cell-text-dark"> 24/7</span>
            </>
          </TableRow>

          <tfoot>
            <tr>
              <th></th>
              <td className="table-subscription-button pro-button">
                <button
                  className="button-large"
                  onClick={() => {
                    open("Pro");
                    if (modals.period) {
                      setPPlan(5);
                    } else {
                      setPPlan(4);
                    }
                  }}
                >
                  <span>QUERO ASSINAR</span>
                </button>
              </td>
              <td className="table-subscription-button">
                <button className="button-large">
                  <a
                    href="https://paradigma.finance/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    QUERO ASSINAR
                  </a>
                </button>
              </td>
              <td className="table-subscription-button">
                <button
                  className="button-large"
                  onClick={() => {
                    open("Light");
                    if (modals.period) {
                      setPPlan(3);
                    } else {
                      setPPlan(2);
                    }
                  }}
                >
                  <span>QUERO ASSINAR</span>
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      {modals.isOpened && <Modals />}
    </>
  );
};

export default connect(mapState, {
  openModal,
  modalType,
  setPPlan,
})(SubscriptionTable);
