const initialState = {
  landingPageModal: false,
  modalType: "",
  type: 0,
  isOpened: false,
  email: "",
  terms: false,
  price: 639.6,
  oldPrice: 639.6,
  discount: undefined,
  pplan: 4,
  period: false,
  coupon: "",
  codeId: "",
  blackFriday: false,
};

export const modals = (state = initialState, action) => {
  switch (action.type) {
    case "SET_BLACK_FRIDAY":
      return {
        ...state,
        blackFriday: action.payload,
      };
    case "LANDING_PAGE_MODAL":
      return {
        ...state,
        landingPageModal: action.payload,
      };
    case "OPEN_MODAL":
      return {
        ...state,
        isOpened: action.payload,
      };
    case "MODAL_TYPE":
      return {
        ...state,
        modalType: action.payload,
      };
    case "SET_MODAL":
      return {
        ...state,
        type: action.payload,
      };
    case "SET_TERMS":
      return {
        ...state,
        terms: action.payload,
      };
    case "SET_EMAIL":
      return {
        ...state,
        email: action.payload,
      };
    case "SET_PRICE":
      return {
        ...state,
        price: action.payload,
      };
    case "SET_DISCOUNT":
      return {
        ...state,
        discount: action.payload,
      };
    case "SET_PERIOD":
      return {
        ...state,
        period: action.payload,
      };
    case "SET_PPLAN":
      return {
        ...state,
        pplan: action.payload,
      };
    case "SET_OLD_PRICE":
      return {
        ...state,
        oldPrice: action.payload,
      };
    case "SET_CODE_ID":
      return {
        ...state,
        codeId: action.payload,
      };
    case "SET_COUPON":
      return {
        ...state,
        coupon: action.payload,
      };
    default:
      return state;
  }
};
