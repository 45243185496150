import React from "react";
// import setaPink from "../../images/seta-pink.svg"
import Button from "../button";

const CallCard = () => {
  return (
    <>
      <div className="section">
        <div className="page-padding">
          <div className="container-large padding-vertical padding-huge">
            <div className="background-color-pink padding-xxlarge stylle-radius">
              <div className="flex-center padding-horizontal padding-large">
                <h2 className="heading-xlarge text-color-white text-align-center umami--ranking-page-click--newsletter-button">
                  Assine nossa newsletter
                </h2>
                <div className="text-size-large text-color-white margin-vertical margin-xsmall">
                  Descubra por que milhares de pessoas em mais de 25 países leem
                  a Café com Satoshi.
                </div>
                <div className="buttons-wrapper">
                  <Button
                    id="f6ddbc1d-f434-721e-096d-86024dacc9bc"
                    namesClass="button-white w-inline-block"
                    title="Receba Grátis"
                    color="text-color-pink"
                    link="https://www.cafecomsatoshi.com.br/"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallCard;

