import React, { useEffect, useState } from "react";
import api from "../../services/global/api-paradigma";
import ReactPaginate from "react-paginate";

import Crypto from "./crypto";
import Menu from "./menu";
import MenuCrypto from "./menu-crypto";
import HotCoins from "../hot-coins";

import loadingGif from "../../videos/loading.gif";
import searchoutline from "../../images/search-outline.svg";

const CryptoTable = () => {
  const [brlCoins, setBrlcoins] = useState([]);
  const [btcCoins, setBtccoins] = useState([]);
  const [usdCoins, setUsdCoins] = useState([]);
  const [sliceStart] = useState(0);
  const [sliceEnd] = useState(50);
  const [coins, setCoins] = useState([]);
  const [symbol, setSymbol] = useState("$");
  const [currency, setCurrency] = useState("usd");
  const [isCurrencySelectorOpen, setIsCurrencySelectorOpen] = useState(false);
  const [isCategorySortOpen, setIsCategorySortOpen] = useState(false);
  const [coinGroups, setCoinGroups] = useState("$");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [isLinkOpen, setIsLinkOpen] = useState(false);
  const [linkOpenIndex, setLinkOpenIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [coinsWithPosts, setCoinsWithPosts] = useState({});
  const [pages, setPages] = useState(95);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [coinSearch, setCoinSearch] = useState([]);
  const [openHotCoins, setOpenHotCoins] = useState(false);
  const [star] = useState({});

  useEffect(() => {
    setLoading(true);
    async function getBrlCoins() {
      try {
        const coinsResult = await api.get("/api/v2/coins");
        coinsResult.data.coins.map((el) => {
          star[el.symbol] = {
            star: el.star,
            inWallet: el.inWallet,
            link: `https://app.paradigma.education/carteiras?wallet=${el.profile.name}`,
          };
          return star;
        });
        const response = await api.post("/api/v2/coins/table", {
          tableType: "brl",
          page: 0,
          pageSize: 50,
        });
        setBrlcoins(
          response.data.data ? response.data.data.coins : response.data.coins
        );
      } catch (e) {
        setBrlcoins([]);
      }
    }

    setLoading(false);

    async function getBtcCoins() {
      try {
        const response = await api.post("/api/v2/coins/table", {
          tableType: "btc",
          page: 0,
          pageSize: 50,
        });
        setBtccoins(
          response.data.data ? response.data.data.coins : response.data.coins
        );
      } catch (e) {
        setBtccoins([]);
      }
    }

    async function getUsdCoins() {
      try {
        const response = await api.post("/api/v2/coins/table", {
          tableType: "usd",
          page: 0,
          pageSize: 50,
        });
        const usdCoins = response.data.data
          ? response.data.data.coins
          : response.data.coins;
        setCoins(usdCoins);
        setUsdCoins(usdCoins);

        const totalPages = response.data.data
          ? response.data.data.totalPages
          : response.data.totalPages;
        setPages(totalPages);
      } catch (e) {
        setCoins([]);
        setUsdCoins([]);
      }
    }

    async function getCoinGroups() {
      try {
        const response = await api.get("/api/v1/coin-groups");
        setCoinGroups(response.data.groupedCoins);
      } catch (e) {
        setCoinGroups([]);
      }
    }

    async function getCoinCategory() {
      try {
        const response = await api.get("/api/v1/coin-category-list");
        setCategories(
          response.data.data
            ? response.data.data.categories
            : response.data.categories
        );
      } catch (e) {
        setCategories([]);
      }
    }

    async function getCoinsWithPosts() {
      try {
        const response = await api.get("/api/v2/post-with-coins");
        setCoinsWithPosts(
          response.data.coinsWithPosts
            ? response.data.coinsWithPosts
            : response.data.data.coinsWithPosts
        );
      } catch (e) {
        setCoinsWithPosts([]);
      }
    }

    const all = async () => {
      await getBrlCoins();
      await getBtcCoins();
      await getUsdCoins();
      await getCoinGroups();
      await getCoinCategory();
      await getCoinsWithPosts();
    };
    all();
  }, [star]);

  const handleCategorySelect = (name) => {
    setLoading(true);
    // setSelectedCategory(name);
    const coinsBeforeFilter =
      currency === "usd" ? usdCoins : currency === "btc" ? btcCoins : brlCoins;
    const coinsCategorized = Object.values(categories[name]).map(
      (entry) => entry.coin
    );
    const filteredCoins = coinsBeforeFilter.filter((coin) =>
      coinsCategorized.includes(coin.ID)
    );

    if (filteredCoins.length > 0) {
      setCoins(filteredCoins);
      setIsCategorySortOpen(false);
      setLoading(false);
      return;
    }

    if (filteredCoins.length === 0) {
      setSelectedCategory("");
    }

    setLoading(false);
  };

  const handleLinkOpen = (indexOpen) => {
    setLinkOpenIndex(indexOpen);
    setIsLinkOpen(!isLinkOpen);
  };

  const openCurrencySelector = () => {
    setIsCurrencySelectorOpen(!isCurrencySelectorOpen);
  };

  const openCategorySort = () => {
    setIsCategorySortOpen(!isCategorySortOpen);
  };

  const selectCurrency = async (currency, symboll) => {
    setCurrency(currency);
    setSymbol(symboll);
    setIsCurrencySelectorOpen(false);
    setLoading(true);

    if (currency === "btc") {
      setCoins(btcCoins);
    }

    if (currency === "usd") {
      setCoins(usdCoins);
    }

    if (currency === "brl") {
      setCoins(brlCoins);
    }

    setLoading(false);
  };

  const handlePageChange = async (event) => {
    setCurrentPage(event.selected);
    setLoading(true);
    const result = await api.post("/api/v2/coins/table", {
      tableType: currency,
      page: event.selected,
      pageSize: 50,
    });
    const coins = result.data.data ? result.data.data.coins : result.data.coins;
    setCoins(coins);

    if (currency === "usd") {
      setUsdCoins(coins);
    }

    if (currency === "btc") {
      setBtccoins(coins);
    }

    if (currency === "brl") {
      setBrlcoins(coins);
    }

    setLoading(false);
  };

  const handleTextChange = (e) => {
    setSearchValue(e.target.value.toLowerCase());
    searchCoins();
  };

  const searchCoins = () => {
    return coinSearch.filter((coin) =>
      coin.symbol
        .toLowerCase()
        .startsWith(searchValue.slice(0, Math.max(coin.symbol.length - 1, 1)))
    );
  };

  const handleSearch = (e) => {
    if (searchValue && e.key === "Enter") {
      e.preventDefault();
      searchCoins();
    }
  };

  const handleInputFocus = () => {
    setOpenHotCoins(true);
  };

  const handlePageSearch = async (rank, id) => {
    const itemPerPage = 50;

    let page = Number(rank) / itemPerPage;
    let pageCurrent = Math.ceil(page);

    setLoading(true);

    const result = await api.post("/api/v2/coins/table", {
      tableType: currency,
      page: pageCurrent - 1,
      pageSize: 50,
    });
    const coins = result.data.data ? result.data.data.coins : result.data.coins;

    const filteredCoins = coins.filter((coin) => coin.ID === id);

    setCoins(filteredCoins);

    if (currency === "usd") {
      setUsdCoins(coins);
    }

    if (currency === "btc") {
      setBtccoins(coins);
    }

    if (currency === "brl") {
      setBrlcoins(coins);
    }

    setLoading(false);
  };

  const selectOriginalOrder = async (currency, symboll) => {
    setCurrentPage(0);
    setCurrency(currency);
    setSymbol(symboll);
    setLoading(true);
    const result = await api.post("/api/v2/coins/table", {
      tableType: currency,
      page: currentPage,
      pageSize: 50,
    });
    const coins = result.data.data ? result.data.data.coins : result.data.coins;

    setCoins(coins);
    setUsdCoins(coins);
    setSearchValue("");
    setLoading(false);
  };

  return (
    <>
      <div className="section">
        <div className="page-padding">
          <div className="container-large padding-bottom padding-xxlarge">
            <div className="criptotable-wrapper">
              {loading ||
              (coins !== null && coins !== undefined && coins.length < 1) ? (
                <div className="loader-main-container">
                  <img
                    className="loader-main"
                    src={loadingGif}
                    alt="loading..."
                  />
                </div>
              ) : (
                <>
                  <div className="criptotable-row margin-bottom margin-xsmall">
                    <Menu
                      coins={coins}
                      openCurrencySelector={openCurrencySelector}
                      selectCurrency={selectCurrency}
                      currency={currency}
                      isCurrencySelectorOpen={isCurrencySelectorOpen}
                      categories={categories}
                      openCategorySort={openCategorySort}
                      handleCategorySelect={handleCategorySelect}
                      isCategorySortOpen={isCategorySortOpen}
                      selectOriginalOrder={selectOriginalOrder}
                    />
                    <div style={{ position: "relative" }}>
                      <form className="search w-form">
                        <input
                          type="search"
                          className="search-input w-input"
                          maxLength="256"
                          name="query"
                          placeholder="Busca"
                          id="search"
                          autoComplete="off"
                          onKeyPress={handleSearch}
                          value={searchValue}
                          onChange={handleTextChange}
                          onClick={handleInputFocus}
                        />
                        <img
                          src={searchoutline}
                          loading="lazy"
                          alt=""
                          className="search-icon-absoluto"
                        />
                        <input
                          type="submit"
                          value="Search"
                          className="search-button w-button"
                        />
                      </form>
                      <HotCoins
                        openHotCoins={openHotCoins}
                        setOpenHotCoins={setOpenHotCoins}
                        coinSearch={coinSearch}
                        setCoinSearch={setCoinSearch}
                        searchValue={searchValue}
                        handlePageSearch={handlePageSearch}
                      />
                    </div>
                  </div>
                  <div className="criptotable-search-container">
                    <MenuCrypto coins={coins} setCoins={setCoins} />
                    {coins &&
                      coins.length >= 1 &&
                      coins.slice(sliceStart, sliceEnd).map((coin, index) => {
                        return (
                          <Crypto
                            index={index}
                            key={index}
                            marketRank={coin.market_cap_rank}
                            image={coin.image}
                            symbol={coin.symbol}
                            symboll={symbol}
                            name={coin.name}
                            currentPrice={Number(
                              coin.current_price
                            ).toLocaleString()}
                            priceChange1h={
                              coin.price_change_percentage_1h_in_currency
                            }
                            priceChange24h={
                              coin.price_change_percentage_24h_in_currency
                            }
                            priceChange7d={
                              coin.price_change_percentage_7d_in_currency
                            }
                            marketCap={coin.market_cap}
                            volume={coin.total_volume}
                            fullyDilutedValuation={coin.fully_diluted_valuation}
                            coinGroups={coinGroups}
                            selectedCategory={selectedCategory}
                            coinId={coin.ID}
                            handleLinkOpen={handleLinkOpen}
                            isLinkOpen={isLinkOpen}
                            linkOpenIndex={linkOpenIndex}
                            coinsWithPosts={coinsWithPosts}
                            star={star}
                          />
                        );
                      })}
                    <ReactPaginate
                      breakLabel="..."
                      previousLabel="<"
                      nextLabel=">"
                      forcePage={currentPage}
                      onPageChange={handlePageChange}
                      pageRangeDisplayed={5}
                      pageCount={pages}
                      renderOnZeroPageCount={null}
                      containerClassName={
                        searchValue
                          ? "pagination-wrapper-display-none"
                          : "pagination-wrapper margin-vertical margin-small"
                      }
                      pageClassName="pagination-component w-inline-block"
                      pageLinkClassName="text-size-regular text-color-dark-gray"
                      nextLinkClassName="text-size-regular text-color-dark-gray"
                      previousLinkClassName="text-size-regular text-color-dark-gray"
                      previousClassName="pagination-component w-inline-block"
                      nextClassName="pagination-component w-inline-block"
                      breakClassName="pagination-component w-inline-block"
                      breakLinkClassName="text-size-regular text-color-dark-gray"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CryptoTable;
