import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import StripeForm from "./stripe";
const ModalForm = ({ modals }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, {
    locale: "pt-BR",
  });
  const options = {
    locale: "pt-BR",
    fonts: [
      {
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap",
      },
    ],
    appearance: {
      variables: {
        fontFamily: "Inter",
        borderRadius: "4px",
      },
      rules: {
        ".Label": {
          marginBottom: "8px",
          fontSize: "13px",
          fontWeight: 400,
          color: "#27224e",
        },
        ".Input": {
          padding: "8px 12px",
          fontSize: "14px",
          lineHeight: "24px",
          border: "1px solid #e0e0e0",
          boxShadow: "none",
          color: "#212121",
        },
        ".Input::placeholder": {
          fontWeight: 400,
          color: "#999",
        },
        ".Input:focus": {
          borderColor: "#e0e0e0",
          boxShadow: "none",
        },
      },
    },
  };

  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <StripeForm modals={modals} />
      </Elements>
    </>
  );
};

export default ModalForm;
