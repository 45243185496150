// import React, { useState, useEffect } from "react";
import React, { useEffect } from "react";

// import { intervalToDuration } from "date-fns";

import iconBrazil from "../../../images/flag-brazil.png";

const ActionBar = ({ isTrack }) => {
  // const [duration, setDuration] = useState({});

  useEffect(() => {
    // setInterval(() => {
    //   setDuration(
    //     intervalToDuration({
    //       start: new Date(),
    //       end: new Date("2022-11-30T23:59:59"),
    //     })
    //   );
    // }, 1000);
  }, []);

  return (
    <>
      <div
        className={
          isTrack
            ? "stickbar-section stickbar-section-tracks"
            : "stickbar-section"
        }
      >
        <div className="page-padding">
          <div className="text-size-medium text-color-white">
            <img
              src={iconBrazil}
              width="13"
              height="13"
              alt="Bandeira Brasil"
              style={{ marginRight: "10px" }}
            />
            {/* {isTrack ? ( */}
            <span>
              O único conteúdo brasileiro consumido pelos maiores participantes
              globais do mercado.
            </span>
            {/* ) : ( */}
            {/* <span> */}
            {/*   <span style={{ fontWeight: "bold" }}>CYBER WEEK</span>: 2 anos por */}
            {/*   1 na assinatura Paradigma Pro com o cupom */}
            {/*   <span style={{ fontWeight: "bold" }}> CYBER </span>| ⏰ */}
            {/*   <span style={{ color: "#3de8a0" }}>{` ${duration.days || "0"}d ${ */}
            {/*     duration.hours || "0" */}
            {/*   }h ${duration.minutes || "0"}m ${ */}
            {/*     duration.seconds || "00" */}
            {/*   }s`}</span> */}
            {/* </span> */}
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionBar;
