import React from "react";
import CardBottom from "./card-bottom";
import CardTop from "./card-top";

const Community = ({ modals, openModal, modalType }) => {
  return (
    <>
      <CardTop />
      <CardBottom modals={modals} openModal={openModal} modalType={modalType} />
    </>
  );
};

export default Community;

