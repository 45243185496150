import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { openModal, modalType } from "../../../store/actions/modals";
import { isLogged } from "../../../utils/cookie";

import Button from "../../button";
import Modals from "../../modals";
import TracksDropdown from "../../tracks-dropdown";

import logoIcon from "../../../images/logo-paradigma.svg";
import chevrondownoutline from "../../../images/chevron-down-outline-2-1.svg";

const mapState = (state) => ({
  modals: state.modals,
});

const HeaderMenu = ({ modals, openModal, current, modalType }) => {
  const userSession = isLogged();
  const [openNav, setOpenNav] = useState(false);
  const [type, setType] = useState("Pro");
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const toggleDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  useEffect(() => {
    if (openModal) {
      if (current === "tracks") setType("Light");
      modalType(type);
    }
  }, [current, modalType, openModal, type]);

  return (
    <>
      <div className="section-navbar padding-vertical padding-xxsmall">
        <div className="page-padding">
          <div className="container-large">
            <div className="navbar-grid-duo">
              <div
                data-animation="default"
                data-collapse="medium"
                data-duration="400"
                data-easing="ease"
                data-easing2="ease"
                role="banner"
                className="navbar padding-vertical padding-xxsmall w-nav"
              >
                <Link to="/" className="nav-image-logo w-nav-brand">
                  <img
                    src={logoIcon}
                    loading="lazy"
                    alt=""
                    className="image-logo"
                  />
                </Link>
                <nav className="nav" id={openNav ? "active-nav" : ""}>
                  <div
                    className={
                      current === "sobre-nos"
                        ? "nav-menu-links page-about-us"
                        : "nav-menu-links"
                    }
                  >
                    <Link
                      to="/"
                      className="text-size-medium text-weight-medium padding-vertical padding-xxsmall w-nav-link"
                      id={current === "home" ? "w--current" : ""}
                    >
                      Início
                    </Link>
                    <div id="nav-line-bottom"></div>
                  </div>
                  <div
                    className={
                      current === "sobre-nos"
                        ? "nav-menu-links page-about-us"
                        : "nav-menu-links"
                    }
                  >
                    <Link
                      to="/pro"
                      className="text-size-medium text-weight-medium padding-vertical padding-xxsmall w-nav-link"
                      id={current === "pro" ? "w--current" : ""}
                    >
                      Planos
                    </Link>
                    <div id="nav-line-bottom"></div>
                  </div>
                  <div className="nav-menu-links">
                    <Link
                      to="/sobre-nos"
                      className="text-size-medium text-weight-medium padding-vertical padding-xxsmall w-nav-link"
                      id={current === "sobre-nos" ? "w--current" : ""}
                    >
                      Sobre Nós
                    </Link>
                    <div id="nav-line-bottom"></div>
                  </div>
                  <div
                    className={
                      current === "sobre-nos"
                        ? "nav-menu-links page-about-us"
                        : "nav-menu-links"
                    }
                  >
                    <Link
                      to="/ranking"
                      className="text-size-medium text-weight-medium padding-vertical padding-xxsmall w-nav-link"
                      id={current === "ranking" ? "w--current" : ""}
                    >
                      Ranking
                    </Link>
                    <div id="nav-line-bottom"></div>
                  </div>
                  <div
                    className={
                      current === "sobre-nos"
                        ? "nav-menu-links page-about-us"
                        : "nav-menu-links"
                    }
                    style={{ position: "relative" }}
                  >
                    <div
                      className="menudropdown-tracks"
                      onClick={toggleDropdown}
                    >
                      <div className="menudropdown-tracks-title-container">
                        <div
                          id={current === "tracks" ? "w--current" : ""}
                          className="text-size-medium text-weight-medium padding-vertical padding-xxsmall w-nav-link menudropdown-tracks-title"
                        >
                          Trilhas
                        </div>
                        <img
                          className="menudropdown-tracks-arrow"
                          src={chevrondownoutline}
                          alt="icon arrow"
                        />
                      </div>

                      <div id="nav-line-bottom"></div>
                    </div>
                    <TracksDropdown isOpenDropdown={isOpenDropdown} />
                  </div>
                </nav>
                <div
                  className="nav-button-mobile w-nav-button"
                  onClick={() => setOpenNav(!openNav)}
                >
                  <div
                    className={`${
                      current === "tracks" || current === "sobre-nos"
                      ? "w-icon-nav-menu-white" 
                      : null
                    } w-icon-nav-menu`}
                  ></div>
                </div>
              </div>
              {!userSession ? (
                <div
                  id="w-node-c3264975-3374-429e-8820-9bc466de5dcb-66de5db1"
                  className="buttons-wrapper"
                >
                  <Button
                    id="68837769-e847-d008-7511-91f44f0fba77"
                    namesClass="button-text-white w-inline-block"
                    title="Login"
                    link="https://app.paradigma.education/login"
                  />
                  <div
                    data-w-id="f823bb56-e822-7198-827e-593bca1f9b42"
                    className="button-medium w-inline-block"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      try {
                        window.umami(`Alguém clicou em assinar!`);
                      } catch (err) {
                        console.info("?", err);
                      }
                      openModal(!modals.isOpened);
                    }}
                  >
                    <div>Assinar</div>
                  </div>
                </div>
              ) : (
                <div
                  id="w-node-c3264975-3374-429e-8820-9bc466de5dcb-66de5db1"
                  className="buttons-wrapper"
                >
                  <Button
                    id="68837769-e847-d008-7511-91f44f0fba77"
                    namesClass="button-text-white w-inline-block"
                    title="APP"
                    link="https://app.paradigma.education/"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {modals.modals && <Modals />}
      </div>
    </>
  );
};

export default connect(mapState, { openModal, modalType })(HeaderMenu);
