import React, { useEffect, useState } from "react";

import api from "../../../../services/global/api-feargreed";
import Button from "../../../button";
import Modals from "../../../modals";

const CardBottom = ({ modals, openModal, modalType }) => {
  const [usuarios, setUsuarios] = useState("7663");
  const [type] = useState("Pro");

  useEffect(() => {
    async function getTotalUsarios() {
      try {
        const response = await api.get("/api/v2/website/users-count");
        setUsuarios(response.data.count);
      } catch (e) {
        console.log(e);
      }
    }
    getTotalUsarios();
  }, []);

  useEffect(() => {
    if (openModal) {
      modalType(type);
    } 
  }, [modalType, type, openModal]);

  return (
    <>
      <div className="section background-color-darkest-gray teste">
        <div className="page-padding">
          <div className="container-large padding-vertical padding-huge">
            <div className="max-width-huge align-center">
              <h2 className="heading-xlarge text-align-center text-color-white">{`${usuarios.toLocaleString()} pessoas já lucraram com as ideias da Paradigma Pro. A próxima história de sucesso pode ser você.`}</h2>
              <div
                className="buttons-wrapper justify-center margin-top margin-medium"
                onClick={() => {
                  openModal(!modals.isOpened);
                }}
              >
                <Button
                  id="a857ee2a-a088-9d0d-a49e-71134bc276a4"
                  namesClass="button-large w-inline-block"
                  title="Assine"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-horizontal justify">
          <div className="column-image">
            <div className="cover-image cover-image-1 animation-image-column1"></div>
          </div>
          <div className="column-image column-image-2">
            <div className="cover-image cover-image-2 animation-image-column2"></div>
          </div>
          <div className="column-image column-image-3">
            <div className="cover-image cover-image-3 animation-image-column3"></div>
          </div>
          <div className="column-image column-image-4">
            <div className="cover-image cover-image-4 animation-image-column4"></div>
          </div>
        </div>
      </div>

      {modals.isOpened && <Modals />}
    </>
  );
};

export default CardBottom;
