import React from "react";

import iconFalse from "../../../../images/icon-false.svg";
import iconTrue from "../../../../images/icon-true.svg";

const TableRow = ({children, one, two, three, color, valuation}) => {
  return <>
    <tbody>
      <tr>
        <th className={`table-cell ${color ? "table-cell-back" : ""} cell-feature`}>{children}</th>
        <td className={`table-cell ${color ? "table-cell-back" : ""}`}>
          {valuation 
          ? <div className="table-cell-valuation">{one}</div>
          : <img style={{width: "12px"}} src={one ? iconTrue : iconFalse} alt="icon" />
          }
        </td>
        <td className={`table-cell ${color ? "table-cell-back" : ""}`}>
          {valuation 
          ? <div className="table-cell-valuation">{two}</div>
          : <img style={{width: "12px"}} src={two ? iconTrue : iconFalse} alt="icon" />
          }
        </td>
        <td className={`table-cell ${color ? "table-cell-back" : ""} `}>
          {valuation 
          ? <div className="table-cell-valuation">{three}</div>
          : <img style={{width: "12px"}} src={three ? iconTrue : iconFalse} alt="icon" />
          }
        </td>
      </tr>
    </tbody>
  </>
}

export default TableRow;