import React from "react";
import { Rating } from "react-simple-star-rating";

import lockClosedOutline from "../../../images/lock-closed-outline.svg";
import chevronDownOutline from "../../../images/chevron-down-outline-2-1.svg";
import linkOutline from "../../../images/link-outline.svg";
import { decodeObjFromCookie } from "../../../utils/cookie";

const Crypto = ({
  index,
  marketRank,
  image,
  symbol,
  symboll,
  name,
  currentPrice,
  priceChange1h,
  priceChange24h,
  priceChange7d,
  marketCap,
  volume,
  fullyDilutedValuation,
  coinId,
  coinGroups,
  selectedCategory,
  handleLinkOpen,
  isLinkOpen,
  linkOpenIndex,
  coinsWithPosts,
  star,
}) => {
  const volumeOverCap = (volume / marketCap) * 100;
  const marketOverValuation = (marketCap / fullyDilutedValuation) * 100;
  const hour24h = Number(priceChange24h);
  const userSession = decodeObjFromCookie("_session");
  const countStar = (coin) => {
    if (coin !== undefined) return coin.star;
    return 1;
  };
  const starColor = (star) => {
    if (star >= 0 && star <= 1) {
      return "#000";
    } else if (star > 1.1 && star <= 2) {
      return "#BF2600";
    } else if (star > 2.1 && star <= 3) {
      return "#FF8B00";
    } else if (star > 3.1 && star <= 4) {
      return "#FFC400";
    } else {
      return "#36B37E";
    }
  };

  return (
    <>
      <div className="cripto-grid colum-2">
        <div
          id="w-node-_6c9e0d69-e812-0599-e0e0-9217ff7c5167-49152174"
          className="divisor"
        ></div>
        <div className="cripto-box margin-left margin-xxsmall">
          <div className="text-size-small text-color-dark-gray">
            {marketRank}
          </div>
        </div>

        <div
          id="w-node-_6c9e0d69-e812-0599-e0e0-9217ff7c516b-49152174"
          className="cripto-box margin-left margin-xxsmall"
        >
          <img src={image} loading="lazy" alt="" className="cripto-image" />
          <div
            className="text-size-medium text-weight-bold text-color-dark-gray"
            style={{ fontWeight: "bold", textTransform: "uppercase" }}
          >
            {symbol}
          </div>
        </div>

        <div className="cripto-box margin-left margin-xsmall">
          <div className="text-size-medium text-weight-medium text-color-dark-gray">
            {selectedCategory
              ? { selectedCategory }
              : coinGroups[coinId] &&
                coinGroups[coinId][0] &&
                coinGroups[coinId][0].categories &&
                coinGroups[coinId][0].categories[0]
              ? coinGroups[coinId][0].categories[0]
              : null}
          </div>
        </div>

        <div
          id="w-node-_6c9e0d69-e812-0599-e0e0-9217ff7c5172-49152174"
          className="cripto-box"
        >
          <div className="text-size-medium text-weight-medium text-color-dark-gray">
            {symboll}
            {currentPrice}
          </div>
        </div>

        <div
          id="w-node-_6c9e0d69-e812-0599-e0e0-9217ff7c5175-49152174"
          className="cripto-box"
        >
          <div
            className="text-size-medium text-weight-medium text-color-dark-gray"
            style={{ color: priceChange1h < 0 ? "#e15241" : "#4eaf0a" }}
          >
            {Number(priceChange1h).toFixed(1)}%
          </div>
        </div>

        <div className="cripto-box">
          <div
            className="text-size-medium text-weight-medium text-color-dark-gray"
            style={{ color: hour24h < 0 ? "#e15241" : "#4eaf0a" }}
          >
            {hour24h.toFixed(1)}%
          </div>
        </div>

        <div className="cripto-box">
          <div
            className="text-size-medium text-weight-medium text-color-dark-gray"
            style={{ color: priceChange7d < 0 ? "#e15241" : "#4eaf0a" }}
          >
            {Number(priceChange7d).toFixed(1)}%
          </div>
        </div>

        <div className="cripto-box margin-left margin-xsmall avaliacao-column">
          {userSession && star && star[coinId] ? (
            <Rating
              initialValue={countStar(star[coinId])}
              fillColor={starColor(star[coinId].star || 0)}
              size={17}
              readonly
              allowFraction
            />
          ) : (
            <img src={lockClosedOutline} loading="lazy" alt="" />
          )}
          {userSession && star && star[coinId] && star[coinId].inWallet ? (
            <>
              <span className="text-size-small text-style-allcaps text-weight-medium text-color-dark-gray span-avaliacao">
                <a href={star[coinId].link}>Carteira</a>
              </span>
            </>
          ) : null}
        </div>

        <div className="cripto-box">
          <div className="text-size-medium text-weight-medium text-color-dark-gray">
            {symboll}
            {marketCap >= 1000000000
              ? Number(
                  Math.round((marketCap / 1000000000) * 1000) / 1000
                ).toLocaleString()
              : Number(
                  Math.round((marketCap / 1000000) * 1000) / 1000
                ).toLocaleString()}{" "}
            {marketCap >= 1000000000 ? "B" : "M"}
          </div>
        </div>

        <div
          id="w-node-_6c9e0d69-e812-0599-e0e0-9217ff7c5183-49152174"
          className="cripto-box"
        >
          <div
            className="text-size-medium text-weight-medium text-color-dark-gray"
            style={{
              color:
                volumeOverCap >= 50 || volumeOverCap >= 100
                  ? "#36B37E"
                  : volumeOverCap >= 10 && volumeOverCap < 50
                  ? "#FFC400"
                  : volumeOverCap >= 2 && volumeOverCap < 10
                  ? "#FF8B00"
                  : volumeOverCap >= 0.25 && volumeOverCap < 2
                  ? "#091E42"
                  : "#BF2600",
            }}
          >
            {volumeOverCap === Infinity || isNaN(volumeOverCap)
              ? "-"
              : `${Number(volumeOverCap).toFixed(2)} %`}
          </div>
        </div>

        <div
          id="w-node-_6c9e0d69-e812-0599-e0e0-9217ff7c5186-49152174"
          className="cripto-box"
        >
          <div
            className="text-size-medium text-weight-medium text-color-dark-gray"
            style={{
              color:
                marketOverValuation >= 50 || marketOverValuation >= 100
                  ? "#36B37E"
                  : marketOverValuation >= 10 && marketOverValuation < 50
                  ? "#FFC400"
                  : marketOverValuation >= 2 && marketOverValuation < 10
                  ? "#FF8B00"
                  : volumeOverCap >= 0.25 && volumeOverCap < 2
                  ? "#091E42"
                  : "#BF2600",
            }}
          >
            {marketOverValuation === Infinity || isNaN(marketOverValuation)
              ? "-"
              : `${Number(marketOverValuation).toFixed(2)} %`}
          </div>
        </div>

        <div data-hover="false" data-delay="0" className="w-dropdown">
          {userSession &&
          coinsWithPosts[name.toLowerCase()] &&
          coinsWithPosts[name.toLowerCase()].length > 0 ? (
            <>
              <div
                className="cripto-dropdown _1 w-dropdown-toggle"
                onClick={() => handleLinkOpen(index)}
              >
                <img src={linkOutline} loading="lazy" alt="" />
                <img
                  src={chevronDownOutline}
                  loading="lazy"
                  alt=""
                  className="icon-small"
                />
              </div>

              {isLinkOpen && index === linkOpenIndex ? (
                <div className="dropdown-list1 w--open">
                  {coinsWithPosts[name.toLowerCase()]
                    .slice(0, 3)
                    .map((coinPost) => {
                      return (
                        <a
                          style={{
                            color: "#222222",
                            textDecoration: "none",
                            backgroundColor: "transparent",
                          }}
                          href={`https://app.paradigma.education/feed/post/${coinPost.id}`}
                        >
                          {coinPost.title}
                        </a>
                      );
                    })}
                </div>
              ) : null}
            </>
          ) : (
            <img src={lockClosedOutline} loading="lazy" alt="" />
          )}
        </div>
      </div>
    </>
  );
};

export default Crypto;
