import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";

import Footer from "../components/footer";
import ActionBar from "../components/header/action-bar";
import HeaderMenu from "../components/header/menu";
import Button from "../components/button";
import Reports from "../components/sobre-nos/reports";
import WithUs from "../components/sobre-nos/with-us";
import Media from "../components/sobre-nos/media";
import Depositions from "../components/sobre-nos/depositions";
import Modals from "../components/modals";
import fimage from "../images/fimage-about-head.png";
import group from "../images/Group-10947_1.png";
import setagreen from "../images/seta-green.svg";
import { openModal, modalType } from "../store/actions/modals";

const mapState = (state) => ({
  modals: state.modals,
});

export const SobreNos = ({ modals, openModal, modalType }) => {
  const [type] = useState("Pro");

  useEffect(() => {
    if (openModal) {
      modalType(type);
    }
  }, [modalType, type, openModal]);

  return (
    <>
      <div className="page-wrapper">
        <MetaTags>
          <title>A vanguarda do conhecimento em Bitcoin & Criptomoedas</title>
          <meta
            name="description"
            content="Produzimos pesquisa original e análises acionáveis sobre criptomoedas. Testamos os produtos mais inovadores do mercado para trazer o alpha até você. Nosso conteúdo é como nenhum outro no Brasil. Tire a prova você mesmo."
          />
          <meta
            property="og:title"
            content="A vanguarda do conhecimento em Bitcoin & Criptomoedas"
          />
          <meta
            property="og:description"
            content="Produzimos pesquisa original e análises acionáveis sobre criptomoedas. Testamos os produtos mais inovadores do mercado para trazer o alpha até você. Nosso conteúdo é como nenhum outro no Brasil. Tire a prova você mesmo."
          />
        </MetaTags>
        <ActionBar />
        <div className="main-wrapper">
          <div className="section_header page-about">
            <HeaderMenu current="sobre-nos" />
            <div className="page-padding">
              <div className="container-large padding-vertical padding-huge">
                <div className="main-grid grid-custom">
                  <div
                    id="w-node-_41485098-b357-94a3-8433-767498c6bae8-2815217b"
                    className="grid-wrapper"
                  >
                    <h2 className="heading-huge text-color-white">
                      A vanguarda do conhecimento em Bitcoin &amp; Criptomoedas
                    </h2>
                    <div className="text-size-large text-color-white margin-top margin-xsmall">
                      Produzimos pesquisa original e análises acionáveis sobre
                      criptomoedas. Testamos os produtos mais inovadores do
                      mercado para trazer o alpha até você. Nosso conteúdo é
                      como nenhum outro no Brasil. Tire a prova você mesmo.
                    </div>
                    <div className="buttons-wrapper margin-top margin-large">
                      <Button
                        id="41485098-b357-94a3-8433-767498c6baf9"
                        namesClass="button-withe-secondary w-inline-block umami--sobre-nos-click--conheca-nosso-conteudo-button"
                        title="Conheça nosso conteúdo"
                        link="https://app.paradigma.education/feed"
                      />
                      {/* <img src={seta} loading="lazy" alt="" className="icon-xsmall margin-left margin-tiny"/> */}
                    </div>
                  </div>
                  <div
                    id="w-node-_3291484f-e3c3-b165-a89d-117d24e62dc1-2815217b"
                    className="grid-wrapper custom-inner"
                  >
                    <img
                      src={fimage}
                      loading="lazy"
                      sizes="(max-width: 767px) 100vw, (max-width: 991px) 95vw, 816px"
                      alt=""
                      className="full-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-description">
            <div className="page-padding">
              <div className="container-large padding-bottom padding-huge">
                <h2 className="heading-large margin-bottom margin-large">
                  Alguns dos nossos relatórios:
                </h2>

                <Reports />
              </div>
            </div>
          </div>
          <div className="section padding-bottom padding-huge">
            <div className="page-padding">
              <div className="container-large padding-top padding-xlarge">
                <div className="main-grid grid-custom">
                  <div
                    id="w-node-_41485098-b357-94a3-8433-767498c6bb81-2815217b"
                    className="grid-wrapper"
                  >
                    <div
                      id="w-node-_41485098-b357-94a3-8433-767498c6bb82-2815217b"
                      className="max-width-medium margin-bottom margin-huge"
                    >
                      <h2 className="heading-xlarge">
                        A 1ª research do Brasil especializada em cripto
                      </h2>
                      <div className="text-size-large text-color-dark-gray margin-top margin-xsmall">
                        Juntamos os melhores talentos do país a tecnologias
                        avançadas de leitura da blockchain para te ajudar a
                        decidir onde, como e quando investir.
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-cd73f0a8-44ab-134e-8627-53a9fc79dc8c-2815217b"
                    className="grid-wrapper size-image"
                  >
                    <img
                      src={group}
                      loading="lazy"
                      sizes="(max-width: 767px) 92vw, (max-width: 991px) 600px, 856px"
                      alt=""
                      className="full-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Depositions />
          <div className="section page-about">
            <div className="page-padding">
              <div className="container-large padding-vertical padding-huge">
                <div className="background-color-main-green padding-xxlarge stylle-radius">
                  <div className="flex-center padding-horizontal padding-large">
                    <h2 className="heading-xlarge text-color-white text-align-center">
                      Assine agora — Se não ficar satisfeito, cancele em até 7
                      dias e receba o dinheiro de volta
                    </h2>
                    <div className="buttons-wrapper margin-top margin-xsmall">
                      <div
                        data-w-id="fc21cbab-eeef-2866-da67-9c9e94c74ba6"
                        className="button-white w-inline-block umami--page-sobre-nos-click--assinar-button"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          openModal(!modals.isOpened);
                        }}
                      >
                        <div>Assinar</div>
                        <img
                          src={setagreen}
                          loading="lazy"
                          alt=""
                          className="icon-xsmall margin-left margin-tiny"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Media />
              </div>
            </div>
          </div>
          <WithUs />
        </div>
        <Footer />
      </div>
      {modals.isOpened && <Modals />}
    </>
  );
};

export default connect(mapState, { openModal, modalType })(SobreNos);
