import jwt_decode from "jwt-decode";

export const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const deleteCookie = (cname, domain) => {
  window.localStorage.removeItem(cname);
  return;
};

export const getObjectFromCookie = (cname) => {
  const obj = window.localStorage.getItem(cname);

  if (obj === "") {
    return null;
  }

  if (obj) {
    const obj_parsed = obj;
    return obj_parsed;
  }

  return null;
};

export const decodeObjFromCookie = (cname) => {
  const obj = window.localStorage.getItem(cname);
  if (obj) {
    const obj_parsed = jwt_decode(obj);
    return obj_parsed;
  }

  return null;
};

export const getUserDetailsFromCookie = () => {
  const session = window.localStorage.getItem("_session");
  if (session) {
    const sessionRet = jwt_decode(session);
    return sessionRet;
  }
  return null;
};

export const getTokenFromCookie = () => {
  const session = window.localStorage.getItem("_session");
  if (session) {
    const sessionRet = JSON.parse(session);
    return sessionRet;
  }

  return null;
};

export const isLogged = () => {
  const session = document.cookie.split(";").filter((el) => {
    return el.trim().startsWith("isLogged");
  });
  return session.length > 0;
};
