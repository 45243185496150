import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { openModal, modalType } from "../../store/actions/modals";
import Modals from "../modals";

import "../../styles/landing-tracks.css";

const mapState = (state) => ({
  modals: state.modals,
});

const SignParadigma = ({ modals, openModal, modalType }) => {
  const [type] = useState("Pro");

  useEffect(() => {
    if (openModal) {
      modalType(type);
    }
  }, [modalType, type, openModal]);

  return (
    <>
      <div className="section-sign-paradigma">
        <div className="page-padding">
          <div className="container-large padding-xxlarge">
            <div className="main-grid paradigma-sign">
              <div className="landing-tracks-text-primary-x-large paradigma-section-left title-vire-pro">
                Um só pagamento desbloqueia todo nosso conteuúdo
              </div>

              <div className="paradigma-section-right">
                <div className="right-title-small">Plano Pro anual</div>
                <div className="right-title-large">
                  R$ 119,90<span className="right-title-medium">/mês</span>
                </div>
                <div className="right-title-small">
                  pagamento anual de R$ 1438,60
                </div>
                <button
                  onClick={() => {
                    openModal(!modals.isOpened);
                  }}
                >
                  eu quero
                </button>
              </div>
            </div>
          </div>
        </div>
        {modals.isOpened && <Modals />}
      </div>
    </>
  );
};

export default connect(mapState, { openModal, modalType })(SignParadigma);
